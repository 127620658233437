const tool = {
  secToTime: (sec: string) => {
    const newDate = new Date(parseInt(sec));
    const hour = newDate.getHours();
    const min = newDate.getMinutes();
    const date = newDate.getDate();
    const month = newDate.getMonth()+1;
    const year = newDate.getFullYear();
    return `${hour < 10 ? `0${hour}` : hour}:${min < 10 ? `0${min}` : min} ${date < 10 ? `0${date}` : date}.${month < 10 ? `0${month}` : month}.${year}`;
  },
  showGlobalMessage: (message: string) => {
    if (!document.getElementById('global-message')) {
      const element = document.createElement('div');
      //const elementContainer =
      element.id = 'global-message';
      element.innerText = message;
      document.body.appendChild(element);
      setTimeout(() => {
        element.remove();
      }, 2000);
    }
  }
};

export default tool;