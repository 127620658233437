import React, {useState, useEffect, useRef} from "react";
import s from './TableItem.module.scss';
import api from "api";
import tool from "tool";

export default function TableItem(props: any) {

  const {params} = props.match;
  const {id: idParent} = params;

  const notFindRef = useRef<any>(null);

  const [list, setList] = useState<any[]>([]);
  const [page, setPage] = useState<String>("1");
  const [isUsed, setUsed] = useState<Boolean>(false);
  const [findValue, setFindValue] = useState('');
  const [editItemValue, setEdit] = useState({editIndex: -1, editValue: ''});
  const [tableData, setTableData] = useState({count_items: 0, alias: ''});

  const LIMIT:number = 500;

  useEffect(() => {
    const unsubscribe = api.request(`${api.apiTable}/${idParent}`, 'get', null, (response: any) => {
      if (response.success) {
        setTableData(response.success);
      }
      if (response.error) {
        tool.showGlobalMessage(response.error);
      }
    });
    return () => unsubscribe();
  }, [idParent]);

  useEffect(() => {
    if (!findValue) {
      notFindRef.current.innerText = '';
      return;
    }
    if (findValue.length < 5) {
      notFindRef.current.innerText = 'Длина строки поиска меньше 6 символов';
      return;
    }
    api.request(`${api.apiCoupon}/${idParent}`, 'post', {find: findValue}, (response: any) => {
      if (response.success) {
        setList(response.success);
        notFindRef.current.innerText = '';
      }
      if (response.error) {
        notFindRef.current.innerText = response.error;
      }
    });
  }, [findValue, idParent]);

  useEffect(() => {
    if (!findValue) {
      let data: object = {page, count: LIMIT, sort: {id: "asc"}};
      if (isUsed) {
        data = {...data, used: 1};
      }
      api.request(`${api.apiCoupon}/${idParent}`, 'get', data, (response: any) => {
        if (response.success) {
          setList(response.success);
        }
        if (response.error) {
          tool.showGlobalMessage(response.error);
        }
      });
    }
  }, [idParent, page, isUsed, findValue]);

  const editItem = (value: string):void => {
    setEdit({...editItemValue, editValue: value});
  };

  const saveEditItem = (idItem: string, field: string, value: string): void => {
    if (idItem && field && value) {
      api.request(`${api.apiCoupon}/${idParent}/${idItem}`, 'patch', {
        item_data: {[field]: value}
      }, (response: any) => {
        if (response.success) {
          const cloneList = [...list];
          cloneList[editItemValue.editIndex][field] = value;
          setList(cloneList);
        }
        setEdit({editIndex: -1, editValue: ''});
        tool.showGlobalMessage(response.success || response.error)
      });
    }
  };

  const deleteItem = (index: number, idItem: string) => {
    if (idItem) {
      if (window.confirm('Удалить выбранный элемент?')) {
        api.request(`${api.apiCoupon}/${idParent}/${idItem}`, 'delete', null, (response: any) => {
          if (response.success) {
            const cloneList = [...list];
            cloneList[index] = {...cloneList[index], deleted: true};
            setList(cloneList);
          }
          tool.showGlobalMessage(response.success || response.error);
        });
      }
    }
  };

  const usedItem = (index: number, idItem: string, used: number) => {
    if (idItem) {
      const date = new Date();
      let timestamp = used === 1 ? date.getTime() : 0;
      api.request(`${api.apiCoupon}/${idParent}/${idItem}`, 'patch', {
        item_data: {used, timestamp}
      }, (response: any) => {
        if (response.success) {
          const cloneList = [...list];
          cloneList[index] = {...cloneList[index], used, timestamp};
          setList(cloneList);
        }
        tool.showGlobalMessage(response.success || response.error)
      });
    }
  };

  const addItem = () => {
    let value = prompt('Введите значение');
    if (value) {
      let newObject: object = {value, used: 0, timestamp: 0};
      api.request(`${api.apiCoupon}/${idParent}`, 'put', {
        item_data: newObject
      }, (response: any) => {
        if (response.success) {
          newObject = {...newObject, id: response.success};
          const cloneList = [...list];
          cloneList.splice(0, 0, newObject);
          setList(cloneList);
          tool.showGlobalMessage('Создана новая запись');
        } else {
          tool.showGlobalMessage(response.error);
        }
      });
    }
  };

  const {editIndex, editValue} = editItemValue;

  let pages = [];
  if(tableData.count_items){
    const countPage = Math.floor(tableData.count_items / LIMIT);
    for(let i = 1; i <= countPage; i++) {
      pages.push(i);
    }
  }

  const {alias} = tableData;

  return (
    <div>
      <h4>{alias}</h4>
      {!!list && <div className={s.filters}>
        <div className={s.filtersSearch}>
          <div>
            <input value={findValue} onChange={(e) => setFindValue(e.target.value)} placeholder='поиск'/>
            {!!findValue && <button onClick={() => setFindValue('')}>&times;</button>}
          </div>
          <span ref={notFindRef}/>
        </div>
        <div className='d-flex-between'>
          <label>использованные <input type='checkbox' onChange={(e) => setUsed(e.target.checked)}/></label>
          <button className={s.addButton} onClick={addItem}>добавить запись</button>
        </div>
      </div>}
      <div className={s.items}>
        {list.map((item: any, index: number) => {
          const {id, value, timestamp, used, deleted} = item;
          return (
            <div key={id} className={`${s.item} ${deleted ? s.deleted : ''} ${used ? s.used : ''}`}>
              {editIndex === index && <div className={s.editBox}>
                <input value={editValue} placeholder='значение' onChange={(e) => editItem(e.target.value)} />
                <button onClick={() => saveEditItem(id,'value', editItemValue.editValue)}>сохранить</button>
              </div>}
              <div className={s.values}>
                {editIndex !== index && <h4 onClick={() => !deleted ? setEdit({editIndex: index, editValue: value}) : {}}>{value}</h4>}
                {!!used && <div className={s.itemDate}>использован: {tool.secToTime(timestamp)}</div>}
                {!used && <div className={s.itemDate}>не использован</div>}
              </div>
              <div className={s.buttons}>
                {!used && <button onClick={() => usedItem(index, id, 1)}>использовать</button>}
                {!!used && <button onClick={() => usedItem(index, id, 0)}>сбросить</button>}
                <button onClick={() => deleteItem(index, id)}>удалить</button>
              </div>
              {deleted && <span>удалено</span>}
            </div>
          )
        })}
      </div>
      {pages.length > 0 && <div className={s.pagination}>
        <select onChange={(e) => setPage(e.target.value)}>
          {pages.map(item => {
            return (
              <option key={item} value={item}>{item}</option>
            )
          })}
        </select>
      </div>}
    </div>
  )
}