import React, {useEffect, useState} from 'react';
import {HashRouter, Route, Switch, Link } from 'react-router-dom';
import Auth from "./container/Auth/Auth";
import Table from "./container/Table/Table";
import TableItem from "./container/TableItem/TableItem";
import User from "./container/User/User";
import Help from "./container/Help/Help";
import './App.scss';
import api from "./api";

function App() {

  const [login, setLogin] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    api.request(api.apiUser, 'checkLogin', null, (response: any) => {
      setLoading(true);
      setLogin(response);
    });
  }, []);

  return (
    <div className="App">

        <HashRouter>
          {(isLoading && login) && <header>
            <div>
              <div className='app-menu'>
                <Link to='/table'>Базы данных</Link>
                <Link to='/user'>Пользователи</Link>
                <Link to='/help'>HELP</Link>
              </div>
              <button onClick={() => {
                setLogin(false);
                Auth.logout();
              }}>выйти</button>
            </div>
          </header>}

          {isLoading && <div className='app-content'>
            <Switch>
              <Route path={`/auth`} component={(props: any) => (<Auth {...props} setLogin={setLogin} />)}/>
              <Route exact path={`/table`} component={(props: any) => (<Table {...props} />)}/>
              <Route exact path={`/table/:id`} component={(props: any) => (<TableItem {...props} />)}/>
              <Route exact path={`/user`} component={(props: any) => (<User {...props} />)}/>
              <Route exact path={`/help`} component={(props: any) => (<Help {...props} />)}/>
            </Switch>
          </div>}

        </HashRouter>

    </div>
  );
}

export default App;
