import React, {useState} from "react";
import api from "api";
import s from './Auth.module.scss'

export default function Auth(props: any) {

  const {setLogin} = props;

  const [login, setValueLogin] = useState('');
  const [password, setValuePass] = useState('');

  const loginUser = () => {
    api.request(api.apiUser, 'post', {login, password}, (response: any) => {
      if (response.success) {
        setLogin(true);
        window.location.href = '/#/';
      }
    });
  };

  return (
    <div className={s.container}>
      <div>
        <input onChange={e => setValueLogin(e.target.value)} value={login} placeholder='логин'/>
        <input onChange={e => setValuePass(e.target.value)} value={password} placeholder='пароль'/>
        <button onClick={loginUser}>OK</button>
      </div>
    </div>
  )
}

Auth.logout = () => {
  api.request(api.apiUser, 'delete', null, (response: any) => {
    window.location.href = '/#/auth';
  });
};