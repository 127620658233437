import React, {useState, useEffect, useRef} from "react";
import s from './Table.module.scss';
import api from "api";
import tool from "../../tool";

export default function Table() {

  const fileRef = useRef<any>(null);
  const [list, setList] = useState<any[]>([]);
  const [createMode, setCreateMode] = useState<any>(null);
  const [editMode, setEditMode] = useState<any>({editIndex: -1, editValue: ''});

  useEffect(() => {
    const unsubscribe = api.request(api.apiTable, 'get', null, (response: any) => {
      if (response.success) {
        setList(response.success);
      }
      if (response.error) {
        tool.showGlobalMessage(response.error);
      }
    });
    return () => unsubscribe();
  }, []);

  const saveItem = (idItem: string, field: string, value: string): void => {
    if (idItem && field && value) {
      const cloneList = [...list];
      cloneList[editMode.editIndex][field] = value;
      setList(cloneList);
      setEditMode({editIndex: -1, editValue: ''});
      api.request(`${api.apiTable}/${idItem}`, 'patch', {
        item_data: {[field]: value}
      }, (response: any) => tool.showGlobalMessage(response.success || response.error));
    } else {
      tool.showGlobalMessage("Укажите значение");
    }
  };

  const createItem = () => {

    let formData = new FormData();
    formData.append('alias', createMode.alias);
    formData.append('file', createMode.file[0]);
    setCreateMode(null);
    const unsubscribe = api.request(api.apiTable, 'upload', formData, (response: any) => {
      if (response.success) {
        tool.showGlobalMessage(`БД создана под номером ${response.success.id}`);
        setList([...list, response.success]);
      }
      if (response.error) {
        tool.showGlobalMessage(response.error);
      }
    });
    return () => unsubscribe();
  };

  const deleteItem = (index: number, idItem: string) => {
    if (idItem) {
      if (window.confirm('Удалить выбранную базу данных со всеми данными?')) {
        api.request(`${api.apiTable}/${idItem}`, 'delete', null, (response: any) => {
          if (response.success) {
            const cloneList = [...list];
            cloneList.splice(index, 1);
            setList(cloneList);
          }
          tool.showGlobalMessage(response.success || response.error);
        });
      }
    }
  };


  const download = (filename:string, text: any) => {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/html;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const getReport = (idItem: string) => {
    const unsubscribe = api.request(`${api.apiTable}/report/${idItem}`, 'get', null, (response: any) => {
      if (response.success) {
        const {info, couponUsed} = response.success;
        const {alias, count_items, count_used_items, timestamp, id} = info;
        let innerHTML = '';
        innerHTML += `<h2>${alias}</h2>`;
        innerHTML += `<h4>ID: ${id}</h4>`;
        innerHTML += `<ul><li>всего записей: ${count_items}</li><li>использовано: ${count_used_items}</li></ul>`;
        innerHTML += '<h2>Список использованных купонов</h2>';
        let listItem = '';
        couponUsed.forEach((item: any) => {
          listItem += `<li>&nbsp;&nbsp;&nbsp;${item.value} — ${tool.secToTime(item.timestamp)}</li>`;
        });
        innerHTML += `<ol>${listItem}</ol>`;
        innerHTML = `<html lang="ru"><body>${innerHTML}</body></html>`;
        download(`${alias.replace(' ','_')}_report.html`, innerHTML);
        // const win = window.open("", "Title");
        // if (win) {
        //   const {info, couponUsed} = response.success;
        //   const {alias, count_items, count_used_items, timestamp, id} = info;
        //   win.document.body.innerHTML += `<h2>${alias}</h2>`;
        //   win.document.body.innerHTML += `<h4>ID: ${id}</h4>`;
        //   win.document.body.innerHTML += `<ul><li>всего записей: ${count_items}</li><li>использовано: ${count_used_items}</li></ul>`;
        //   win.document.body.innerHTML += '<h2>Список использованных купонов</h2>';
        //   let listItem = '';
        //   couponUsed.forEach((item: any) => {
        //     listItem += `<li>&nbsp;&nbsp;&nbsp;${item.value} — ${tool.secToTime(item.timestamp)}</li>`;
        //   });
        //   win.document.body.innerHTML += `<ol>${listItem}</ol>`;
        //   if(window.confirm('Скачать отчёт?')) {
        //     download('report.html', win.document.body.innerHTML);
        //   }
        // }
      }
      if (response.error){
        tool.showGlobalMessage(response.error);
      }
    });
    return () => unsubscribe();
  };

  const {editIndex, editValue} = editMode;

  return (
    <div>
      <div>
        <button onClick={() => {
          if (!createMode) {
            setCreateMode({alias: ''});
          } else {
            setCreateMode(null);
          }
        }}>{!createMode ? 'загрузить [csv]' : 'отменить загрузку'}</button>
      </div>
      {!!createMode && <div className={`${s.loadMode} d-flex-between`}>
        <input value={createMode.alias} placeholder='Введите название БД' onChange={(e) => {
          setCreateMode({...createMode, alias: e.target.value});
        }}/>
        <input ref={fileRef} type='file' onChange={(e) => {
          setCreateMode({...createMode, file: e.target.files});
        }}/>
        <button onClick={createItem}>Начать выгрузку</button>
      </div>}
      {list.map((item, index) => {
        const {id, alias, count_items, count_used_items} = item;
        return (
          <div key={index} className={s.item}>
            <div>
              {editIndex !== index && <h4 onClick={(e) => {
                setEditMode({editIndex: index, editValue: alias})
              }}>{alias}</h4>}
              <span className={s.id}>[id: {id}]</span>
              <ul>
                <li>всего <b>{count_items}</b></li>
                <li>выдано <b>{count_used_items}</b></li>
              </ul>
              {editIndex === index && <div className={s.editBox}>
                <input
                  value={editValue}
                  placeholder='значение'
                  onChange={(e) => setEditMode({...editMode, editValue: e.target.value})}
                />
                <button onClick={(e) => saveItem(id, 'alias', editValue)}>сохранить</button>
              </div>}
            </div>
            <button onClick={() => {window.location.href = `/#/table/${id}`}}>открыть</button>
            <button onClick={() => getReport(id)}>скачать отчёт</button>
            <button onClick={() => deleteItem(index, id)}>удалить</button>
          </div>
        )
      })}
    </div>
  );
}