import React from "react";
import api from "api";
import s from './Help.module.scss';

export default function Help() {
  const urlCoupon = api.apiCoupon;
  return (
    <div className={s.help}>
      <h4>Типы прав доступа</h4>
      <ul>
        <li><i>Права назначаются каждой базе данных индивидуально</i></li>
        <li><b>получать данные</b> - получать списки и данные этих списков</li>
        <li><b>изменять данные</b> - редактировать любые свойства данных</li>
        <li><b>удалять данные</b> - удалять элементы списка купонов</li>
        <li><b>создать запись</b> - добавлять новые купоны</li>
        <li><b>поиск</b> - поиск в списке купонов</li>
        <li><b>удалять БД</b> - удалять базу данных купонов со всеми связанными данными</li>
        <li><b>формировать отчёт</b> - формировать отчёт по выбранной базе данных</li>
      </ul>
      <h4>API: Работа с купонами</h4>
      <ul>
        <li>
          <b>Получить случайный купон: </b>
          <br/><i>Для получения случайного купона не требуется авторизация</i>
          <br/><b>GET:</b> <span>{urlCoupon}/</span>id базы данных
          <br/><b>пример:</b> <span>{urlCoupon}/11</span>
          <br/><b>результат:</b> {`{"success":{"id":220229,"value":"65600000000800207306","timestamp":1588143059}}`}
        </li>
      </ul>
    </div>
  );
}