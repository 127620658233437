import axios from "axios";

const api = {
  apiUser: 'https://apicoupon.mediaforce.ru/api/user',
  apiTable: 'https://apicoupon.mediaforce.ru/api/table',
  apiCoupon: 'https://apicoupon.mediaforce.ru/api/coupon',
  request: (url: string, method: string, data: any, callback: any):any => {

    let canLoading = true;

    const loader = {
      show: () => {
        let loader = document.getElementById('loader');
        let blockScreen = document.getElementById('blockScreen');
        if (!loader) {
          loader = document.createElement('div');
          loader.id = 'loader';
          if (method === 'upload') {
            blockScreen = document.createElement('div');
            blockScreen.innerHTML = '<div>Идёт загрузка, ожидайте...</div>';
            blockScreen.id = 'blockScreen';
            document.body.appendChild(blockScreen);
          }
          document.body.appendChild(loader);
        }
      },
      hide: () => {
        let loader = document.getElementById('loader');
        let blockScreen = document.getElementById('blockScreen');
        if (loader) {
          loader.remove();
        }
        if (blockScreen) {
          blockScreen.remove();
        }
      }
    };

    loader.show();

    const responseMethod = (response: any) => {
      loader.hide();
      if (canLoading) {
        if (!!response.data.auth_error) {
          window.location.href = '/#/auth';
          callback(false);
          return false;
        }
        callback(response.data);
      }
    };

    switch (method) {
      case 'post':
        axios.post(url, data).then(responseMethod);
        break;
      case 'get':
        axios.get(url, {params: data}).then(responseMethod);
        break;
      case 'delete':
        axios.delete(url, data).then(responseMethod);
        break;
      case 'patch':
        axios.patch(url, data).then(responseMethod);
        break;
      case 'put':
        axios.put(url, data).then(responseMethod);
        break;
      case 'checkLogin':
        axios.get(url, data).then(responseMethod);
        break;
      case 'upload':
        axios.post(url, data, {headers: {'Content-Type': 'multipart/form-data'}}).then(responseMethod);
        break;
      default:
        loader.hide();
        return true;
    }

    return () => {
      canLoading = false;
    }
  }
};

export default api;