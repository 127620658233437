import React, {useEffect, useState} from "react";
import s from './User.module.scss';
import api from "api";
import tool from "tool";


//patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {}
export interface IUser {
  id?: string,
  login?: string,
  password?: string,
  full_name?: string,
}

export default function User() {

  const [list, setList] = useState<any[]>([]);
  const [listTable, setListTable] = useState<any[]>([]);
  const [listRules, setListRules] = useState<any[]>([]);
  const [editMode, setEditMode] = useState<any>({});
  const [newRulesItems, setNewRulesItems] = useState<any>({});
  const [filterByTable, setFilterByTable] = useState<string | null>(null);

  const TYPES:any = {
    get: 'получать данные',
    post: 'поиск',
    delete: 'удалять данные',
    put: 'создать запись',
    patch: 'изменять данные',
    drop: 'удалять БД',
    report: 'формировать отчёт',
  };

  useEffect((): any => {

    const unsubscribeListTable = api.request(api.apiTable, 'get', null, (response: any) => {
      if (response.success) {
        setListTable(response.success);
      }
    });
    const unsubscribeListRules = api.request(`${api.apiUser}/get_type_rules`, 'get', null, (response: any) => {
      if (response.success) {
        setListRules(response.success);
      }
    });
    return () => {
      unsubscribeListTable();
      unsubscribeListRules();
    }
  }, []);

  useEffect(() => {
    const filter = filterByTable && {filter: {table: filterByTable}};
    const unsubscribeUser = api.request(api.apiUser, 'get', filter, (response: any) => {
      if (response.success) {
        setList(response.success);
      }
      if (response.error) {
        tool.showGlobalMessage(response.error);
      }
    });
    return () => {
      unsubscribeUser();
    }
  }, [filterByTable])

  const saveUser = () => {
    let unsubscribeUser = () => {};
    if (!editMode.id) {
      unsubscribeUser = api.request(`${api.apiUser}`, 'put', editMode, (response: any) => {
        if (response.success) {
          const {login, full_name} = editMode;
          setList([...list, {id: parseInt(response.success), login, full_name}]);
          setEditMode({});
          setNewRulesItems({});
          tool.showGlobalMessage(`Пользователь ${full_name} создан`);
        }
        if (response.error) {
          tool.showGlobalMessage(response.error);
        }
      });
    } else {
      unsubscribeUser = api.request(`${api.apiUser}`, 'patch', editMode, (response: any) => {
        if (response.success) {
          const clone = {...editMode};
          delete clone.permission;
          const findItem = list.findIndex(fItem => fItem.id === clone.id);
          if (findItem >= 0) {
            const cloneList = [...list];
            cloneList[findItem] = {...cloneList[findItem], ...editMode};
            setList(cloneList);
          }
        }
        tool.showGlobalMessage(response.success || response.error);
      });
    }
    return () => unsubscribeUser();
  };

  const startEditMode = (id: string) => {
    const unsubscribeUser = api.request(`${api.apiUser}/${id}`, 'get', null, (response: any) => {
      if (response.success) {
        let {id, permission} = response.success.user;
        permission = Object.values(permission).map((item: any) => ({id_table: item.id_table, rules: item.rules}));
        setEditMode({id, permission});
      }
    });
    return () => unsubscribeUser();
  };

  const deleteUserItem = (index: number, idItem: string) => {
    if (idItem) {
      if (window.confirm('Удалить пользователя?')) {
        const cloneList = [...list];
        cloneList.splice(index, 1);
        setList(cloneList);
        api.request(`${api.apiUser}/${idItem}`, 'delete', null, (response: any) => tool.showGlobalMessage(response.success || response.error));
      }
    }
  };

  const deletePermission = (index: number) => {
    let clone = {...editMode};
    clone.permission.splice(index, 1);
    setEditMode(clone);
  };

  const renderListTable = (dataList: any, callback: (v:string) => void, emptyItem: boolean) => (
    <select onChange={e => callback(e.target.value)}>
      {emptyItem && <option value='' key='-1'>Фильтр по таблицам</option>}
      {dataList.map((item: any, index: number) => (
        <option value={item.id} key={index}>{item.alias}</option>
      ))}
    </select>
  );

  const renderItemCheckboxRules = (permissions: any, onChange: any) => (
    <div className={s.rulesList}>
      {listRules.map((item, index) => {
        const isChecked = !!permissions && !!permissions.length && permissions.includes(item);
        return (
          <label key={index}>
            {TYPES[item]}
            <input
              title={TYPES[item]}
              type='checkbox'
              checked={isChecked}
              onChange={(e) => onChange(item, e.target.checked)}
            />
          </label>
        );
      })}
    </div>
  );

  const setPermission = (rule: string, value: boolean, item: any) => {
    let clone = {...editMode};
    const indexTable = clone.permission.findIndex((fItem: any) => fItem.id_table === item.id_table);
    if (indexTable >= 0 && rule) {
      if (value) {
        clone.permission[indexTable].rules.push(rule);
      } else {
        const indexRule = clone.permission[indexTable].rules.indexOf(rule);
        if (indexRule >= 0) {
          clone.permission[indexTable].rules.splice(indexRule, 1);
        }
      }
      setEditMode(clone);
    }
  };

  const setNewPermission = (idTable: string, rule?: string, value?: boolean) => {
    let clone = {...newRulesItems};
    if (idTable) {
      clone = {...clone, id_table: parseInt(idTable)}
    }
    if (!clone.rules) {
      clone = {...clone, rules: []};
    }
    if (rule) {
      if (value) {
        clone.rules.push(rule);
      } else {
        const indexRule = clone.rules.indexOf(rule);
        if (indexRule >= 0) {
          clone.rules.splice(indexRule, 1);
        }
      }
    }
    setNewRulesItems(clone);
  };

  const renderUserRules = (permission: any) => {
    permission = Object.values(permission);
    return (
      <div>
        <label className={s.labelSectionRules}>Назначенные права</label>
        <div className={s.userPermission}>
          {permission.map((item: any, index: number) => {
            const find = listTable.find(fItem => fItem.id == item.id_table);
            const {alias} = find || {};
            const {rules} = item;
            return (
              <div key={index} className='d-flex-between'>
                <div>
                  <h4>
                    {alias || '---db is deleted---'}
                  </h4>
                  {renderItemCheckboxRules(rules, (rule: string, value: boolean) => setPermission(rule, value, item))}
                </div>
                <button onClick={() => deletePermission(index)}>удалить</button>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderCreateRules = (permission: any) => {

    const availableTable = listTable.reduce((acc, item) => {
      if (permission.findIndex((fItem: any) => fItem.id_table === item.id) < 0) {
        acc.push(item);
      }
      return acc;
    }, []);

    if (!availableTable.length) {
      return '';
    }

    const {rules} = newRulesItems;

    return (
      <div>
        <label className={s.labelSectionRules}>Добавить права</label>
        <div className={`${s.itemNewRules} d-flex-between`}>
          <div>
            {renderListTable(availableTable, setNewPermission, false)}
            <div className='d-flex-between'>
              {renderItemCheckboxRules(newRulesItems.rules, (rule: string, value: boolean) => {
                setNewPermission(newRulesItems.id_table || availableTable[0].id, rule, value);
              })}
            </div>
          </div>
          <button onClick={() => {
            if (!rules || !rules.length) {
              tool.showGlobalMessage('Укажите хотя бы 1 тип доступа');
              return false;
            }
            const {permission} = editMode;
            permission.push(newRulesItems);
            setEditMode({...editMode, permission});
            setNewRulesItems([]);
          }}>добавить</button>
        </div>
      </div>
    );
  };

  const onChangeValueEdit = (e: any, field: string) => {
    const value = e.target.value;
    setEditMode({...editMode, [field]: value});
  };

  const renderEditForm = ({
                            id,
                            login,
                            password,
                            full_name
                          }: IUser) => {
    const {permission} = editMode;
    if (!permission) {
      return '';
    }
    return (
      <div className={s.editForm}>
        <div>
          <div className='d-flex-between'>
            <input placeholder='введите новый логин' value={login || ''} onChange={e => onChangeValueEdit(e, 'login')}/>
            <input placeholder='введите новый пароль' value={password || ''} onChange={e => onChangeValueEdit(e, 'password')}/>
            <input placeholder='введите новое имя' value={full_name || ''} onChange={e => onChangeValueEdit(e, 'full_name')}/>
          </div>
          <div>
            {renderUserRules(permission)}
            {renderCreateRules(permission)}
          </div>
          <button onClick={saveUser}>{!id ? 'создать' : 'сохранить внесенные изменения'}</button>
        </div>
      </div>
    );

  };

  const createNewUser = () => {
    if (!isNaN(editMode.login)) {
      setEditMode({});
      return false;
    }
    setEditMode({
      login: '',
      password: '',
      full_name: '',
      permission: [],
    });
  };

  const createMode = !!editMode && Object.keys(editMode).length > 0 && !editMode.id;

  return (
    <div>
      {listTable.length > 0 && <div className='d-flex-between'>
        <div>
          <button onClick={createNewUser} className={s.addButton}>{createMode ? 'отменить добавление' : 'добавить пользвателя'}</button>
          {createMode && <div>
            {renderEditForm(editMode)}
          </div>}
        </div>
        <div>
          {!createMode && renderListTable(listTable, setFilterByTable, true)}
        </div>
      </div>}
      {list.length > 0 && <div>
        {list.map((item, index) => {
          const {full_name, login, id, password} = item;
          return (
            <div key={index}>
              <div className={s.item}>
                <div>
                  <div>{full_name}</div>
                  <div>
                    <b>логин: </b>{login} {!!password && <span>/ <b>пароль: </b>{password}</span>}
                  </div>
                </div>
                <button onClick={() => {
                  if (editMode.id === id) {
                    setEditMode({});
                  } else {
                    startEditMode(id);
                  }
                }}>
                  {editMode.id !== id ? 'открыть' : <span>&times;закрыть</span>}
                </button>
                <button onClick={(e) => {
                  e.stopPropagation();
                  deleteUserItem(index, id);
                }}>
                  удалить
                </button>
              </div>
              {(!createMode && editMode.id === id) && renderEditForm(editMode)}
            </div>
          );
        })}
      </div>}
    </div>
  );
}